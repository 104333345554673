<template>
  <div class="access-policy">
    <div :class="['_scopes', loading ? '-loading':'']">
      <template v-if="creator !== null">
        <access-policy-scope-user-row
          :user="creator"
          :access-type="accessPolicyType.full"
          :access-types="accessTypes"
          disabled
        >
          <template #description>
            {{ creatorDescription === '' ? $t('accessPolicy.creatorDescription') : creatorDescription }}
          </template>
        </access-policy-scope-user-row>
      </template>
      <div
        v-for="link in value.links"
        :key="link.uid"
      >
        <access-policy-link-row
          :link="link"
          :access-types="accessTypes"
          :disabled="disabled"
          @change="linkChanged(link, $event)"
        />
      </div>
      <div
        v-for="scope in sortedScopes"
        :key="scope.uid"
      >
        <template v-if="canRenderAccessPolicyScope(scope)">
          <access-policy-scope-user-row
            v-if="isStaticUserScope(scope)"
            :user="userFromStaticUserScope(scope)"
            :access-type="scope.accessType"
            :access-types="accessTypesWithRemove"
            :disabled="disabled"
            @change="scopeTypeChanged(scope, $event)"
          />
          <access-policy-scope-group-row
            v-else
            :scope="scope"
            :access-type="scope.accessType"
            :access-types="accessTypesWithRemove"
            :disabled="disabled"
            @change="scopeTypeChanged(scope, $event)"
          />
        </template>
      </div>
      <slot name="after-access-scope" />
      <m-content
        padding-xs
        :padding-bottom="0"
        class="_group"
      >
        {{ $t('accessPolicy.generalAccess') }}
      </m-content>
      <access-policy-scope-row
        :image="companyImage"
        icon="global"
        :access-type="accountAccess"
        :disabled="disabled"
        :access-types="accessTypesWithDisable"
        @change="updateAccount"
      >
        <template #name>
          {{ account.companyName }}
        </template>
        <template #description>
          <div :style="{ whiteSpace: 'pre-wrap'}">
            {{ $t('accessPolicy.accountAccessDescription') }}
            <span v-if="viaAccountAccess.length > 0"> {{ $t('accessPolicy.accountAccessVia') }}</span>
          </div>
          <m-tag-list
            v-if="viaAccountAccess.length > 0"
            xs
            wrap
          >
            <m-tag
              v-for="via in viaAccountAccess"
              :key="via.uid"
              xs
              :title="via.space.title"
              :icon="buildIconFromEntity(via.space)"
            />
          </m-tag-list>
        </template>
      </access-policy-scope-row>
    </div>
    <m-divider none />
    <slot name="before-buttons" />
    <m-content
      padding-xs
      class="_footer"
    >
      <div class="_actions -block">
        <div class="_btn _invite">
          <m-btn
            block
            :disabled="disabled || loading"
            @click.stop="showScopeSelector"
          >
            {{ addUserText === '' ? $t('accessPolicy.inviteButton') : addUserText }}
          </m-btn>
        </div>
      </div>
      <slot name="actions">
        <div class="_actions">
          <div class="_left">
            <m-btn
              v-if="accountHasFeature([experimentFlag.accessRights2])"
              super-light
              :href="$t('accessPolicy.helpCenterLink')"
              hide-border
              icon="question-circle"
              target="_blank"
            >
              {{ $t('accessPolicy.learnMore') }}
            </m-btn>
          </div>
          <div class="_right">
            <slot name="actions-right">
              <div
                v-if="pageLink !== ''"
                class="_btn -page-link"
              >
                <m-btn
                  v-clipboard="pageLink"
                  v-clipboard:success="clipboardSuccessHandler"
                  v-clipboard:error="logCatch(onError)"
                  block
                  icon="link"
                  @click.stop.prevent
                >
                  {{ $t('accessPolicy.copyLink') }}
                </m-btn>
              </div>
            </slot>
          </div>
        </div>
      </slot>
    </m-content>
    <m-dialog
      v-model:value="showModal"
      hide-footer
      no-padding
    >
      <access-policy-scope-selector
        :button-label="addGroupButtonText"
        :access-types="accessTypes"
        @submit="scopeSubmitted"
      />
    </m-dialog>
  </div>
</template>

<script>
import AccessPolicyLinkRow from '@/components/access-policy/AccessPolicyLinkRow.vue';
import AccessPolicyScopeGroupRow from '@/components/access-policy/AccessPolicyScopeGroupRow.vue';
import AccessPolicyScopeRow from '@/components/access-policy/AccessPolicyScopeRow.vue';
import AccessPolicyScopeSelector from '@/components/access-policy/AccessPolicyScopeSelector.vue';
import AccessPolicyScopeUserRow from '@/components/access-policy/AccessPolicyScopeUserRow.vue';
import useAccess from '@/composables/access/access';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useProperties from '@/composables/property/property';
import useSnackBar from '@/composables/snackbar';
import useSpaces from '@/composables/space/spaces';
import { ACCESS_POLICY_TYPE_REMOVE } from '@/lib/constants';
import { DateTime } from 'luxon';
import { EventBus } from '@/lib/event-bus';
import { UserScopeTreeHasher } from '@/lib/user-scope-tree_hasher';
import { accessPolicyType } from 'shared/constants.json';
import { addOrUpdate, removeObjectInArray, updateObjectInArray } from 'shared/lib/array/write';
import { applyPatch, composedAccessPolicy } from '@/lib/access-policy-linking';
import { buildIconFromEntity } from 'shared/lib/icon';
import { canRenderAccessPolicyScope, isStaticUserScope, userFromStaticUserScope } from '@/lib/access-policy-scope';
import {
  cmpAccessRight,
  getAccessTypeOfUser,
  getHighestAccessRight,
  spreadAccessPolicyScopes,
} from '@/lib/access-policy';
import { copy, shallowCopy } from 'shared/lib/copy';
import { experimentFlag } from 'shared/experiments.json';
import { isNullOrUndefined } from 'shared/lib/object/object';
import { logCatch } from '@/lib/logger/logger';

export default {
  name: 'AccessPolicy',
  props: {
    value: {
      type: Object,
      required: true,
    },
    creator: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pageLink: {
      type: String,
      default: '',
    },
    addUserText: {
      type: String,
      default: '',
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
    creatorDescription: {
      type: String,
      default: '',
    },
    suppressConfirm: {
      type: Boolean,
      default: false,
    },
    addGroupButtonText: {
      type: String,
      default: '',
    },
  },
  emits: ['input', 'update:value', 'close'],
  components: { AccessPolicyLinkRow, AccessPolicyScopeGroupRow, AccessPolicyScopeUserRow, AccessPolicyScopeRow, AccessPolicyScopeSelector },
  setup() {
    const snackbar = useSnackBar();
    const { userProperties } = useProperties();
    const { loggedInUser, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const spacesSvc = useSpaces();
    const { accountHasFeature } = useAccess();
    return { snackbar, userProperties, loggedInUser, userLang, account: loggedInUserAccount, spacesSvc, accountHasFeature };
  },
  data() {
    return {
      accessTypesWithDisable: [...this.accessTypes, accessPolicyType.disabled],
      accessTypesWithRemove: [...this.accessTypes, ACCESS_POLICY_TYPE_REMOVE],
      showModal: false,
      logCatch,
      accessPolicyType,
      experimentFlag,
    };
  },
  computed: {
    companyImage() {
      if (this.account.companyImage === null) {
        return '';
      }
      return this.account.companyImage.getURL;
    },
    composedAccountAccess() {
      const vias = [this.value, ...this.value.links.map((link) => composedAccessPolicy(link))];
      const accountAccess = getHighestAccessRight(vias.map((ap) => ap.accountAccess));
      return { accountAccess, via: vias.filter((ap) => ap.accountAccess === accountAccess) };
    },
    accountAccess() {
      return this.composedAccountAccess.accountAccess;
    },
    viaAccountAccess() {
      return this.composedAccountAccess.via.filter((via) => via.uid !== this.value.uid)
        .map((via) => ({ ...via, space: this.spacesSvc.selectSingle(via.space.uid) }));
    },
    sortedScopes() {
      return shallowCopy(this.value.scopes).filter((s) => s?.deletedAt === undefined).sort((a, b) => {
        const aStatic = this.isStaticUserScope(a);
        const bStatic = this.isStaticUserScope(b);
        if (aStatic === bStatic) return 0;
        if (aStatic === true) return 1;
        return -1;
      });
    },
  },
  methods: {
    buildIconFromEntity,
    canRenderAccessPolicyScope,
    isStaticUserScope,
    userFromStaticUserScope,
    onError() {
      this.snackbar.error(this.$t('error.duringCopying'));
    },
    confirmDisconnect(next) {
      return this.$confirm({
        title: this.$t('accessPolicy.disconnectingLink.title'),
        message: { text: this.$t('accessPolicy.disconnectingLink.message') },
        okText: this.$t('accessPolicy.disconnectingLink.cta'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        hideOnSubmit: false,
        onOk() {
          EventBus.$emit('hide-confirm');
          next();
        },
      });
    },
    confirmReconnect(next) {
      return this.$confirm({
        title: this.$t('accessPolicy.reconnectingLink.title'),
        message: { text: this.$t('accessPolicy.reconnectingLink.message'), type: 'info' },
        okText: this.$t('accessPolicy.reconnectingLink.cta'),
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        hideOnSubmit: false,
        onOk() {
          EventBus.$emit('hide-confirm');
          next();
        },
      });
    },
    confirmLoseFull(next) {
      return this.$confirm({
        title: this.$t('accessPolicy.loseFullAccess.title'),
        message: { text: this.$t('accessPolicy.loseFullAccess.message') },
        okText: this.$t('general.change'),
        okType: 'danger',
        maskClosable: true,
        cancelText: this.$t('general.cancel'),
        hideOnSubmit: false,
        onOk() {
          EventBus.$emit('hide-confirm');
          next();
        },
      });
    },
    updateAccount(value) {
      if (cmpAccessRight(value, this.accountAccess) === 0) {
        return;
      }

      const updated = { ...copy(this.value), accountAccess: value };
      if (cmpAccessRight(value, this.accountAccess) < 0) {
        //  If getting more restrictive, evaluate links bc we need to verify if each link gets replaced
        for (let i = 0; i < this.value.links.length; i++) {
          const link = this.value.links[i];
          const linkAP = composedAccessPolicy(link);
          if (cmpAccessRight(value, linkAP.accountAccess) < 0) {
            const modifiedLink = applyPatch(link, { ...linkAP, accountAccess: value });
            updated.links[i] = { ...updated.links[i], deactivatedAt: modifiedLink.deactivatedAt, patch: modifiedLink.patch };
          }
        }
      }

      const emitUpdate = () => {
        this.$emit('input', updated);
        this.$emit('update:value', updated);
      };
      if (this.suppressConfirm) {
        emitUpdate();
        return;
      }

      const confirmDisconnect = (next) => {
        const mustDisconnect = this.value.links.some((oldLink) => {
          const newLink = updated.links.find((link) => link.uid === oldLink.uid);
          return oldLink.deactivatedAt === null && !isNullOrUndefined(newLink.deactivatedAt);
        });
        if (mustDisconnect) {
          this.confirmDisconnect(next);
          return;
        }
        next();
      };
      const confirmLoseFull = (next) => {
        if (!this.hasFullAccessAfterChange({ accountAccess: value })) {
          this.confirmLoseFull(next);
          return;
        }
        next();
      };

      confirmDisconnect(() => confirmLoseFull(() => emitUpdate()));
    },
    showScopeSelector() {
      if (this.disabled) {
        return;
      }
      this.showModal = true;
    },
    linkChanged(oldLink, newLink) {
      const updated = {
        ...this.value,
        links: [newLink],
      };

      const emitUpdate = () => {
        this.$emit('input', updated);
        this.$emit('update:value', updated);
      };
      if (this.suppressConfirm) {
        emitUpdate();
        return;
      }

      const confirmDisconnect = (next) => {
        if (oldLink.deactivatedAt === null && !isNullOrUndefined(newLink.deactivatedAt)) {
          this.confirmDisconnect(next);
          return;
        }
        next();
      };
      const confirmReconnect = (next) => {
        if (oldLink.deactivatedAt !== null && newLink.deactivatedAt === null) {
          this.confirmReconnect(next);
          return;
        }
        next();
      };
      const confirmLoseFull = (next) => {
        if (!this.hasFullAccessAfterChange({ links: [newLink] })) {
          this.confirmLoseFull(next);
          return;
        }
        next();
      };

      confirmDisconnect(() => confirmReconnect(() => confirmLoseFull(() => emitUpdate())));
    },
    scopeSubmitted(scope) {
      this.showModal = false;
      this.updateScopes(spreadAccessPolicyScopes(scope));
    },
    scopeTypeChanged(scope, newType) {
      const newScope = copy(scope);
      newScope.accessType = newType;

      if (newType === ACCESS_POLICY_TYPE_REMOVE) {
        newScope.accessType = accessPolicyType.disabled;
        this.removeScope(newScope);
        return;
      }

      if (this.suppressConfirm || this.hasFullAccessAfterChange({ scopes: [newScope] })) {
        this.updateScopes([newScope]);
        return;
      }
      this.confirmLoseFull(() => this.updateScopes([newScope]));
    },
    removeScope(scope) {
      const deletedAt = DateTime.local().toISO();
      const scopesToDelete = [{ ...scope, deletedAt }];

      if (this.suppressConfirm || this.hasFullAccessAfterChange({ scopes: [scopesToDelete] })) {
        this.updateScopes(scopesToDelete);
        return;
      }
      this.confirmLoseFull(() => this.updateScopes(scopesToDelete));
    },
    hasFullAccessAfterChange(diff) {
      const accessPolicy = copy(this.value);
      if (diff.accountAccess) {
        accessPolicy.accountAccess = diff.accountAccess;
      }
      if (diff.links) {
        if (!isNullOrUndefined(diff.links[0].deletedAt)) {
          removeObjectInArray(accessPolicy.links, diff.links[0]);
        } else {
          const ogLink = accessPolicy.links.find((l) => l.uid === diff.links[0].uid);
          updateObjectInArray(accessPolicy.links, { ...ogLink, ...diff.links[0] });
        }
      }
      if (diff.scopes) {
        if (!isNullOrUndefined(diff.scopes[0].deletedAt)) {
          removeObjectInArray(accessPolicy.scopes, diff.scopes[0]);
        } else {
          updateObjectInArray(accessPolicy.scopes, diff.scopes[0]);
        }
      }

      return getAccessTypeOfUser({
        accessPolicy,
        creator: this.creator,
      }, this.loggedInUser) === accessPolicyType.full;
    },
    updateScopes(newScopes) {
      const hasher = new UserScopeTreeHasher();
      const fillHashesToAccessPolicyScope = (aps) => {
        if (!isNullOrUndefined(aps.scope)) {
          const hash = hasher.treeHash(aps.scope);
          return { ...aps, scopeHash: hash, scope: { ...aps.scope, treeHash: hash } };
        }
        return aps;
      };
      let scopes = this.value.scopes.map(fillHashesToAccessPolicyScope);
      const newSc = newScopes.map(fillHashesToAccessPolicyScope);
      scopes = addOrUpdate(scopes, newSc, 'scopeHash');
      const updated = {
        ...this.value,
        scopes,
      };
      this.$emit('input', updated);
      this.$emit('update:value', updated);
    },
    clipboardSuccessHandler() {
      this.snackbar.success(this.$t('success.copied'));
    },
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  @import "shared/assets/scss/loading";

  .access-policy {
    min-width: 40rem;
    max-width: 60rem;

    ._group {
      font-size: $font-size-2;
      color: $font-color-secondary;
      font-weight: $font-weight-medium;
    }

    ._scopes {
      max-height: 75vh;
      overflow: auto;

      &.-loading {
        pointer-events: none;

        @include loading;
      }
    }

    ._footer {
      display: flex;
      flex-direction: column;
      gap: .8rem;

      ._actions {
        display: flex;
        gap: 1rem;
        justify-content: space-between;

        &.-block{
          display: block;
        }

        ._btn {
          flex: 1 1 50%;
        }
      }
    }
  }
</style>
