<template>
  <div
    class="jira-editor"
  >
    <div class="_item">
      <div class="_label">
        {{ $t('dataSourceEditor.nameLabel') }}
      </div>
      <m-text-field v-model:value="localEntity.name" />
    </div>
    <div class="_item -share-hint">
      {{ $t('dataSourceEditor.shareHint') }}
    </div>
    <div class="_item">
      <m-switch
        v-model:value="localEntity.shared"
        :label="$t('dataSourceEditor.sharedLabel')"
      />
    </div>
    <div class="_item">
      <m-radio-group
        :value="localEntity.authenticationType"
        :options="authenticationTypeOptions"
        inline
        @change="val => localEntity.authenticationType = val"
      />
    </div>
    <template v-if="localEntity.authenticationType === dataSourceAuthenticationType.oauth">
      <div class="_item">
        <div class="_label">
          {{ $t('jiraEditor.serverLabel') }}
        </div>
        <m-text-field
          v-model:value="localEntity.oauthServer"
          :placeholder="$t('jiraEditor.oauthServerPlaceholder')"
        />
      </div>

      <m-btn
        class="_btn"
        :disabled="localEntity.oauthServer === ''"
        :icon="signInIcon"
        :loading="dataSourceLoading"
        @click="signInOAuth(connectUrl)"
      >
        {{ signInButtonText }}
      </m-btn>
    </template>
    <template v-if="localEntity.authenticationType === dataSourceAuthenticationType.oauthv2">
      <m-btn
        small
        super-light
        :href="$t('jiraEditor.oauthv2HelpLink')"
        hide-border
        icon="question-circle"
        target="_blank"
        class="_tutorial"
      >
        {{ $t('jiraEditor.oauthv2Help') }}
      </m-btn>
      <div class="_item">
        <div class="_label">
          {{ $t('jiraEditor.serverLabel') }}
        </div>
        <m-text-field
          v-model:value="localEntity.oauthServer"
          :placeholder="$t('jiraEditor.oauthServerPlaceholder')"
        />
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('jiraEditor.clientId') }}
        </div>
        <m-text-field v-model:value="localEntity.oauthv2ClientId" />
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('jiraEditor.clientSecret') }}
        </div>
        <m-text-field
          v-model:value="localEntity.oauthv2ClientSecret"
          input-type="password"
        />
      </div>

      <m-btn
        class="_btn"
        :disabled="localEntity.oauthServer === ''"
        :icon="signInIcon"
        :loading="dataSourceLoading"
        @click="signInOAuth(oauthV2ConnectUrl)"
      >
        {{ signInButtonText }}
      </m-btn>
    </template>
    <template v-if="localEntity.authenticationType === dataSourceAuthenticationType.apiToken">
      <div class="_tutorial">
        <m-btn
          target="_blank"
          :href="$t('jiraEditor.helpLink')"
          hide-border
          light
          small
          icon="question-circle"
        >
          {{ $t('jiraEditor.tutorial') }}
        </m-btn>
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('jiraEditor.serverLabel') }}
        </div>
        <m-tooltip placement="left">
          <div class="_input">
            <m-text-field
              v-model:value="localEntity.apiServer"
              addon-before="https://"
              :placeholder="$t('jiraEditor.serverPlaceholder')"
            />
          </div>
          <template #title>
            {{ $t('jiraEditor.serverHint') }}
          </template>
        </m-tooltip>
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('general.email') }}
        </div>
        <m-tooltip placement="left">
          <div>
            <m-text-field v-model:value="localEntity.apiUser" />
          </div>
          <template #title>
            {{ $t('jiraEditor.emailHint') }}
          </template>
        </m-tooltip>
      </div>
      <div class="_item">
        <div class="_label">
          {{ $t('jiraEditor.tokenLabel') }}
        </div>
        <m-text-field
          v-model:value="localEntity.apiToken"
          input-type="password"
        />
      </div>
      <div
        v-if="faultyCredentials"
        class="_error-messages"
      >
        <m-alert
          :message="$t('jiraEditor.faultyCredentials')"
          type="error"
        />
      </div>
      <i18n-t
        keypath="jiraEditor.tokenHint"
        tag="div"
        class="_token-hint"
      >
        <template #account>
          <m-link
            target="_blank"
            href="https://id.atlassian.com/manage/api-tokens"
            underline-on-hover
          >{{ $t('jiraEditor.accountHint') }}</m-link>
        </template>
        <template #description>
          <m-link
            target="_blank"
            href="https://confluence.atlassian.com/cloud/api-tokens-938839638.html"
            underline-on-hover
          >{{ $t('jiraEditor.descriptionHint') }}</m-link>
        </template>
      </i18n-t>
    </template>
    <div class="_actions">
      <div class="_btns">
        <m-btn
          class="_btn"
          @click="$emit('close')"
        >
          {{ $t('general.cancel') }}
        </m-btn>
        <m-btn
          color="primary"
          class="_btn"
          :loading="updateDataSourceLoading"
          @click="save"
        >
          {{ $t('general.save') }}
        </m-btn>
      </div>
    </div>
  </div>
</template>

<script>
import useDataSources from '@/composables/integrations/datasources';
import { camelCase } from 'lodash-es';
import { connectUrl, dataSourceIcon } from '@/lib/data-source';
import { copy } from 'shared/lib/copy';
import { dataSourceAuthenticationType } from 'shared/constants.json';
import { dataSourceInstallStatus, jiraInstallStatus } from '@/constants.json';
import { encodeURLValues } from '@/lib/url';
import { initOAuthInstall } from '@/lib/oauth-redirect';

export default {
  name: 'JiraEditor',
  props: {
    entity: {
      type: Object,
      default: () => null,
    },
  },
  emits: ['close'],
  setup() {
    const { fetchDataSource, updateDataSource, updateDataSourceLoading } = useDataSources();
    return {
      fetchDataSource,
      updateDataSource,
      updateDataSourceLoading,
    };
  },
  data() {
    return {
      localEntity: {
        name: '',
        shared: false,
        apiServer: '',
        apiUser: '',
        apiToken: '',
        authenticationType: dataSourceAuthenticationType.apiToken,
      },
      showSuccessMessage: false,
      dataSourceLoading: false,
      camelCase,
      dataSourceAuthenticationType,
      faultyCredentials: false,
    };
  },
  computed: {
    signInButtonText() {
      if (this.localEntity.accessToken !== '') {
        return this.$t('jiraEditor.signInAgain');
      }
      return this.$t('jiraEditor.signIn');
    },
    signInIcon() {
      if (this.localEntity.accessToken !== '') {
        return 'check';
      }
      return 'jira';
    },
    authenticationTypeOptions() {
      const types = [dataSourceAuthenticationType.apiToken, dataSourceAuthenticationType.oauthv2, dataSourceAuthenticationType.oauth];
      return types.map((t) => ({
        label: this.$t(`jiraEditor.authenticationType.${camelCase(t)}`),
        value: t,
      }));
    },
    dataSourceIcon() {
      return dataSourceIcon(this.entity);
    },
    connectUrl() {
      const params = {
        dataSourceId: this.localEntity.uid,
        origin: '/#/oauth-redirect',
      };

      const targetParams = { oauthRedirect: `/api/v1/jira/install?${encodeURLValues(params)}` };
      return `/#/oauth-redirect?${encodeURLValues(targetParams)}`;
    },
    oauthV2ConnectUrl() {
      return connectUrl(this.localEntity);
    },
  },
  methods: {
    signInOAuth(url) {
      this.save(false).then(() => {
        this.dataSourceLoading = true;
        initOAuthInstall(url).then((event) => {
          switch (event.data.status) {
            case jiraInstallStatus.error:
            case jiraInstallStatus.notAllowed:
            case dataSourceInstallStatus.error:
            case dataSourceInstallStatus.notAllowed:
              this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
              break;
            case jiraInstallStatus.success:
            case dataSourceInstallStatus.success:
              this.$showSnackbar({ color: 'success', message: this.$t('dataSource.successfullySynced') });
              this.fetchDataSource({ uid: this.entity.uid, type: this.entity.type }).then((dataSource) => {
                this.localEntity = copy(dataSource);
              }).catch(() => {
                this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
              });
              break;
            default:
              break;
          }
        }).finally(() => {
          this.dataSourceLoading = false;
        });
      });
    },
    stripAPiToken(entity) {
      const cpy = copy(entity);
      if (entity.apiToken === 'hidden') {
        delete cpy.apiToken;
      }
      if (entity.apiToken === '') {
        delete cpy.apiToken;
      }
      return cpy;
    },
    save(close = true) {
      return this.updateDataSource(this.stripAPiToken({ ...this.localEntity, apiServer: `https://${this.localEntity.apiServer}` })).then((dataSource) => {
        this.faultyCredentials = false;
        this.localEntity = copy({ ...dataSource, apiServer: dataSource.apiServer.replace('https://', '') });
        if (close) {
          this.$emit('close');
        }
        return dataSource;
      }).catch((err) => {
        if (err.message.includes('missing api authentication credentials')) {
          this.faultyCredentials = true;
          throw err;
        }
        this.$showSnackbar({ color: 'error', message: this.$t('error.default') });
        throw err;
      });
    },
  },
  mounted() {
    if (this.entity !== null) {
      this.localEntity = copy({ ...this.entity, apiServer: this.entity.apiServer.replace('https://', '') });
    }

    if (this.localEntity.authenticationType === '') {
      this.localEntity.authenticationType = dataSourceAuthenticationType.apiToken;
    }

    if (typeof this.$route.query.data_source_id !== 'undefined') {
      this.showSuccessMessage = true;
    }
    const query = { ...this.$route.query };
    delete query.data_source_id;
    delete query.jira_install_status;

    this.$router.push({ query });
  },
};
</script>

<style scoped lang="scss" type="text/scss">
  .jira-editor {
    ._error-messages {
      margin-bottom: 2rem;
    }

    ._icon {
      margin-right: .4rem;
    }

    ._tutorial {
      margin-bottom: 2rem;
    }

    ._label {
      color: $font-color-secondary;
    }

    ._item {
      margin-bottom: 2rem;

      &.-share-hint {
        padding: 1.4rem;
        background-color: map_get($blue, 'lighten-3');
        border-radius: $input-field-border-radius;
      }
    }

    ._text {
      margin-bottom: 2rem;
      color: $font-color-secondary;
    }

    ._actions {
      display: flex;

      ._btns {
        display: flex;
        margin-left: auto;

        ._btn {
          margin-left: .4rem;
        }
      }
    }

    ._alert {
      margin-bottom: 2rem;
    }

    ._token-hint {
      margin-bottom: 2rem;
      color: $font-color-secondary;
    }
  }
</style>
