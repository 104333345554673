<template>
  <div class="goal-children-list">
    <list-header
      class="_header"
      divider
    >
      <template #left>
        <h6 class="_title">
          {{ $t('goalChildrenList.alignedItems') }}
        </h6>
      </template>
      <template #right>
        <div
          :class="['_right', showCustomizePage || showRight || $store.state.breakpoint.smAndDown ? '-show' : '']"
        >
          <template v-if="canEdit">
            <template v-if="!$store.state.breakpoint.smAndDown">
              <props-filter
                v-if="canEditProperties"
                :value="props"
                :in-card-menu="$store.state.breakpoint.smAndDown"
                @change-visibility="handleDropdownVisibleChange"
                @update:show="updateProps"
                @update:prop-order="updateChildrenPropOrder($event)"
              />
              <group-by
                :properties="goalProperties"
                class="_btn"
                :value="groupByProperty"
                @input="changeGroupBy"
                @dropdown-visible-change="handleDropdownVisibleChange"
              />
            </template>
            <m-dropdown
              v-else
              :title="$t('general.actions')"
            >
              <m-btn
                icon="ellipsis"
                small
                hide-border
              />
              <template #overlay>
                <m-card
                  list
                  no-padding
                >
                  <props-filter
                    v-if="canEditProperties"
                    :value="props"
                    :in-card-menu="$store.state.breakpoint.smAndDown"
                    @change-visibility="handleDropdownVisibleChange"
                    @update:show="updateProps"
                    @update:prop-order="updateChildrenPropOrder($event)"
                  />
                  <group-by
                    :properties="goalProperties"
                    class="_btn"
                    :value="groupByProperty"
                    @input="changeGroupBy"
                    @dropdown-visible-change="handleDropdownVisibleChange"
                  />
                </m-card>
              </template>
            </m-dropdown>
          </template>
          <m-btn-group
            v-if="canCreate"
            class="_btn"
          >
            <m-btn
              small
              class="_btn -new"
              color="primary"
              :loading="createBtnLoading"
              :disabled="!canCreate"
              @click="create({ parent: parent, option: restrictedOptions[0] })"
            >
              {{ $t('general.new') }}
            </m-btn>
            <m-dropdown
              v-if="restrictedOptions.length > 1"
              v-model:value="showCreateOptions"
              :title="$t('propertySettingsForm.visibility')"
              placement="bottomRight"
              close-on-click
              :disabled="!canCreate"
              @change="handleDropdownVisibleChange"
            >
              <m-btn
                small
                color="primary"
                :loading="createBtnLoading"
                :disabled="!canCreate"
                @click="showCreateOptions = true"
              >
                <m-icon
                  size="12"
                  type="down"
                />
              </m-btn>
              <template #overlay>
                <m-card
                  list
                  no-padding
                >
                  <m-card-item
                    v-for="(option, index) in restrictedOptions"
                    :key="index"
                    @click="create({ parent, option })"
                  >
                    {{ getLabel(option) }}
                  </m-card-item>
                </m-card>
              </template>
            </m-dropdown>
          </m-btn-group>
        </div>
      </template>
    </list-header>
    <div class="_content">
      <goal-create-dropdown
        v-if="canCreate && goalGroups.length === 0 && allowedSubItemGoalTypes.length > 0"
        :goal="parent"
        match-trigger-width
        block
        @create-beneath="create"
      >
        <m-btn
          hide-border
          icon="plus"
          light
          :loading="createBtnLoading"
          align-left
          block
        >
          {{ $t('general.new') }}
        </m-btn>
      </goal-create-dropdown>
      <m-selectable
        v-model:value="selectedGoalIds"
        :selector-class="childClass"
        :select-area-class="selectAreaClass"
        :scroll-container-class="scrollContainerClass"
        :disabled="!canEdit"
      >
        <template
          v-for="(group) in goalGroups"
          :key="group.option === null ? -1 : group.option.uid"
        >
          <goal-list-group
            :props="props"
            :items="group.items"
            :group-by-property="groupByProperty"
            :group-by-option="group.option"
            :parent="parent"
            :open-in-modal="openInModal"
            :read-only="readOnly"
            :child-class="childClass"
            :selected-goal-ids="selectedGoalIds"
            :show-plus-btn="showPlusBtn(group)"
            :update-property="updateProperty"
            :goal-creator="goalCreator"
            @context-menu-clicked="handleContextMenu"
            @open="open"
            @progress-click="progressClick"
            @list-before="(entity, list, anchor, toMove) => listBefore(entity)(list, anchor, toMove)"
            @list-after="(entity, list, anchor, toMove) => listAfter(entity)(list, anchor, toMove)"
            @select="selectGoal"
          />
          <m-divider
            no-border
          />
        </template>
      </m-selectable>
      <goals-context-menu
        v-if="canEdit"
        ref="contextMenu"
        :goal-ids="selectedGoalIds"
        :can-create="canCreate"
      />
    </div>
  </div>
</template>
<script>
import GoalCreateDropdown from '@/components/goal/GoalCreateDropdown.vue';
import GoalListGroup from '@/components/goal/GoalListGroup.vue';
import GoalsContextMenu from '@/components/goal/GoalsContextMenu.vue';
import GroupBy from '@/components/list/GroupBy.vue';
import ListHeader from '@/components/list/ListHeader.vue';
import PropsFilter from '@/components/PropsFilter.vue';
import useAccess from '@/composables/access/access';
import useAlignedItemsProperties from '@/composables/customize-page/aligned-items-properties';
import useGoalCycle from '@/composables/goal-cycle/goal-cycle';
import useGoalDetailSort from '@/composables/sort/goal-detail-sort';
import useGoalProperty from '@/composables/property/goal-property';
import useGoalSettings from '@/composables/logged-in-user-account/goal-settings';
import useGoals from '@/composables/goal/goals';
import useInlineEditing from '@/composables/inline-editing';
import useLoggedInUser from '@/composables/logged-in-user/logged-in-user';
import useLoggedInUserAccount from '@/composables/logged-in-user-account/logged-in-user-account';
import useResourceSettings from '@/composables/logged-in-user-account/resource-settings';
import useSnackBar from '@/composables/snackbar';
import useSpaces from '@/composables/space/spaces';
import { CASCADE_CONTEXT_PUBLISHED_VIEW } from '@/lib/constants';
import { accessGroupFlag, accessPolicyType, propertyType } from 'shared/constants.json';
import { groupGoalsByProperty } from '@/lib/goal-grouping';
import { initIdList } from '@/lib/sort';
import { logCatch } from '@/lib/logger/logger';
import { mapActions } from 'vuex';
import { shallowCopy } from 'shared/lib/copy';
import { sortByArray } from 'shared/lib/sort';
import { textByLang } from 'shared/lib/language';

export default {
  name: 'GoalChildrenList',
  props: {
    goals: {
      type: Array,
      required: true,
    },
    parent: {
      type: Object,
      required: true,
    },
    alignedItemsGroupedBy: {
      type: Object,
      default: null,
    },
    updateProperty: {
      type: Function,
      required: true,
    },
    openInModal: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    selectAreaClass: {
      type: String,
      required: true,
    },
    scrollContainerClass: {
      type: String,
      default: 'scroll-container',
    },
    goalCreator: {
      type: Object,
      default: () => ({}),
    },
    allowedSubItemGoalTypes: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const snackbar = useSnackBar();
    const { userHasRight } = useAccess();
    const goalsSvc = useGoals();
    const { properties: goalProperties } = useGoalProperty();

    const { goalSettings } = useGoalSettings();
    const { myTeamsIds, userLang } = useLoggedInUser();
    const { loggedInUserAccount } = useLoggedInUserAccount();
    const { goalCycles } = useGoalCycle();

    const { updateVisibility, childrenProps, updateChildrenPropOrder } = useAlignedItemsProperties();

    const { listBefore, listAfter } = useGoalDetailSort(goalsSvc);

    const inlineEditingSvc = useInlineEditing();

    const { spaces } = useSpaces();
    const { resourceSettings } = useResourceSettings();

    return {
      snackbar,
      updateVisibility,
      updateChildrenPropOrder,
      userHasRight,
      goalSettings,
      goalCycles,
      props: childrenProps,
      userLang,
      account: loggedInUserAccount,
      myTeamsIds,
      listAfter,
      listBefore,
      inlineEditingSvc,
      goalProperties,
      spaces,
      resourceSettings,
    };
  },
  emits: ['change-group-by', 'open', 'progress-click'],
  components: { GoalCreateDropdown, PropsFilter, GroupBy, ListHeader, GoalListGroup, GoalsContextMenu },
  data() {
    return {
      createBtnLoading: false,
      showRight: false,
      childClass: 'goal-child',
      selectedGoalIds: [],
      write: accessPolicyType.write,
      full: accessPolicyType.full,
      showCustomizePage: false,
      showCreateOptions: false,
      showCreateDropdown: false,
    };
  },
  computed: {
    hasForeignEntityReference() {
      return this.userHasRight([accessGroupFlag.foreignEntityReference]);
    },
    canCreate() {
      return this.userHasRight([accessGroupFlag.createGoal]);
    },
    canEditProperties() {
      return this.userHasRight([accessGroupFlag.propertyWriteAccess, accessGroupFlag.goalCycleWriteAccess]);
    },
    options() {
      if (this.groupByProperty === null) {
        return [];
      }

      if (this.groupByProperty.type === propertyType.space) {
        return this.spaces.map((s) => ({
          ...s,
          property: this.groupByProperty,
        }));
      }
      return this.groupByProperty.options.map((o) => ({
        ...o,
        property: this.groupByProperty,
      }));
    },
    sortedOptions() {
      let optionsOrder = this.groupByProperty.propertyOptionOrder.map((uid) => ({ uid }));
      if (this.groupByProperty.type === propertyType.space) {
        optionsOrder = this.resourceSettings.spaceOrder;
      }
      const cp = shallowCopy(this.options);
      return cp.sort(sortByArray(optionsOrder));
    },
    restrictedOptions() {
      const alignedItemsGroupedBy = this.goalProperties.filter((p) => p.uid === this.alignedItemsGroupedBy.uid);
      if (alignedItemsGroupedBy.length === 0) {
        return this.sortedOptions;
      }

      if (alignedItemsGroupedBy[0].isGoalType) {
        return this.sortedOptions.filter((o) => this.allowedSubItemGoalTypes.map((t) => t.uid).includes(o.uid));
      }

      if (alignedItemsGroupedBy[0].type !== propertyType.space) {
        return this.sortedOptions;
      }

      if (this.hasForeignEntityReference) {
        return this.sortedOptions;
      }

      return this.sortedOptions.filter((o) => this.myTeamsIds.includes(o.uid));
    },
    groupByProperty() {
      if (this.alignedItemsGroupedBy === null) {
        return null;
      }
      const filtered = this.goalProperties.filter((p) => p.uid === this.alignedItemsGroupedBy.uid);
      if (filtered.length === 0) {
        return null;
      }
      return filtered[0];
    },
    goalGroups() {
      return groupGoalsByProperty(
        this.goals,
        this.groupByProperty,
        this.sortedOptions,
        initIdList(this.parent.childrenSort.map(({ uid }) => (uid)), this.parent.children.map((c) => c.uid)),
      );
    },
  },
  methods: {
    ...mapActions([
      'createEntityV2',
      'updateEntityV2',
    ]),
    handleContextMenu(event, goal) {
      if (this.selectedGoalIds.includes(goal.uid)) {
        this.$refs.contextMenu.show(event);
        return;
      }
      this.selectedGoalIds = [goal.uid];
      this.$refs.contextMenu.show(event);
    },
    showPlusBtn(group) {
      if (group.option === null) {
        return true;
      }
      if (this.groupByProperty.type === propertyType.space) {
        if (this.hasForeignEntityReference) {
          return true;
        }

        return this.myTeamsIds.includes(group.option.uid);
      }
      return this.allowedSubItemGoalTypes.map((t) => t.uid).includes(group.option.uid);
    },
    updateProps({ property, value }) {
      this.updateVisibility(property, value);
    },
    selectGoal({ goal }) {
      this.selectedGoalIds = [goal.uid];
    },
    getLabel(option) {
      if (this.groupByProperty.type === propertyType.space) {
        return option.title;
      }
      return textByLang(option.label, this.userLang);
    },
    handleDropdownVisibleChange(value) {
      this.showRight = value;
    },
    create({ parent, option }) {
      this.showCreateOptions = false;
      this.showCreateDropdown = false;
      this.createBtnLoading = true;
      switch (this.groupByProperty.type) {
        case propertyType.space:
          this.goalCreator.modifiers.setSpace(option);
          break;
        case propertyType.options:
        case propertyType.singleSelect:
        case propertyType.status:
          this.goalCreator.modifiers.setOption(option);
          break;
        default:
          break;
      }
      this.goalCreator.addChild([parent.uid]).then((newGoal) => {
        this.goalCreator.modifiers.setSpace(null);
        this.goalCreator.modifiers.setOption(null);
        this.inlineEditingSvc.set(newGoal.uid);
      }).catch(logCatch(() => {
        this.snackbar.error();
      })).finally(() => {
        this.createBtnLoading = false;
      });
    },
    changeGroupBy(value) {
      this.$emit('change-group-by', value);
    },
    open(goal) {
      this.$emit('open', goal, this.openInModal || this.context === CASCADE_CONTEXT_PUBLISHED_VIEW);
    },
    progressClick(goal) {
      this.$emit('progress-click', goal);
    },
  },
};
</script>

<style
    scoped
    lang="scss"
    type="text/scss"
>
  .goal-children-list {
    margin-bottom: 4rem;

    ._title {
      user-select: none;
      display: flex;
      align-items: center;
      min-height: 3rem;
      margin: 0 .8rem 0 .4rem;
      font-weight: $font-weight-semibold;
    }

    ._header {
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      ._right {
        display: none;
        align-items: center;
        opacity: 0;
        animation: fadeIn .5s ease-in-out;

        ._btn {
          margin-left: .4rem;
        }

        &.-show {
          display: inline-flex;
          opacity: 1;
        }
      }
    }

    &:hover {
      ._right {
        display: inline-flex;
        opacity: 1;
      }
    }

    ._content {
      padding-top: .4rem;
    }
  }

  ._menu-item-label {
    color: $font-color-secondary;
  }
</style>
