<template>
  <div
    class="access-policy-type-selector"
  >
    <m-dropdown
      v-model:value="show"
      :title="$t('accessPolicyTypeSelector.title')"
      :disabled="disabled"
    >
      <m-btn
        :class="['_button']"
        hide-border
        :disabled="disabled"
      >
        <div class="_text">
          {{ selected.text }}
        </div>
        <div class="_icon-container">
          <m-icon
            v-show="!disabled"
            type="down"
            size="11"
            class="_icon"
          />
        </div>
      </m-btn>
      <template #overlay>
        <m-card
          no-padding
          list
          class="_overlay"
        >
          <m-card-item
            v-for="item in options"
            :key="item.text"
            class="_item"
            :tooltip="item.tooltip"
            tooltip-placement="right"
            :disabled="item.disabled"
            @click="select(item)"
          >
            <div class="_left">
              <div :class="['_text', item.class, item.disabled ? '-disabled' : '']">
                {{ item.text }}
              </div>
              <div :class="['_description', item.disabled ? '-disabled' : '']">
                {{ item.description }}
              </div>
            </div>
            <template #right>
              <div class="_right">
                <m-icon
                  v-if="value === item.value"
                  type="check-mark"
                />
              </div>
            </template>
          </m-card-item>
        </m-card>
      </template>
    </m-dropdown>
  </div>
</template>

<script>
import { ACCESS_POLICY_TYPE_REMOVE } from '@/lib/constants';
import { accessPolicyType } from 'shared/constants.json';
import { sortByArray } from 'shared/lib/sort';

export default {
  name: 'AccessPolicyTypeSelector',
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accessTypes: {
      type: Array,
      default: () => [accessPolicyType.read, accessPolicyType.write, accessPolicyType.full],
    },
  },
  emits: ['input', 'update:value', 'change'],
  components: {},
  data() {
    return {
      selected: '',
      show: false,
      optionsOrder: [
        { value: accessPolicyType.full },
        { value: accessPolicyType.write },
        { value: accessPolicyType.comment },
        { value: accessPolicyType.read },
        { value: accessPolicyType.disabled },
        { value: ACCESS_POLICY_TYPE_REMOVE },
      ],
      optionsDefaults: {
        [accessPolicyType.full]: {
          text: this.$t('accessPolicyTypeSelector.full'),
          description: this.$t('accessPolicyTypeSelector.fullDescription'),
        },
        [accessPolicyType.write]: {
          text: this.$t('accessPolicyTypeSelector.write'),
          description: this.$t('accessPolicyTypeSelector.writeDescription'),
        },
        [accessPolicyType.comment]: {
          text: this.$t('accessPolicyTypeSelector.comment'),
          description: this.$t('accessPolicyTypeSelector.commentDescription'),
        },
        [accessPolicyType.read]: {
          text: this.$t('accessPolicyTypeSelector.read'),
          description: this.$t('accessPolicyTypeSelector.readDescription'),
        },
        [accessPolicyType.disabled]: {
          text: this.$t('accessPolicyTypeSelector.disabled'),
          class: '-danger',
        },
        [ACCESS_POLICY_TYPE_REMOVE]: {
          text: this.$t('accessPolicyTypeSelector.remove'),
          class: '-danger',
        },
      },
    };
  },
  computed: {
    options() {
      return this.accessTypes.map((type) => this.transformType(type)).sort(sortByArray(this.optionsOrder, 'value'));
    },
  },
  methods: {
    transformType(type) {
      return (typeof type === 'string' ? { ...this.optionsDefaults[type], value: type } : { ...this.optionsDefaults[type.value], ...type });
    },
    select(item) {
      if (this.disabled || item.disabled) {
        return;
      }
      this.show = false;
      this.$emit('change', item.value);
      this.$emit('input', item.value);
      this.$emit('update:value', item.value);
    },
    init(value) {
      const f = this.options.filter((o) => o.value === value);
      if (f.length === 0) {
        this.selected = this.transformType(value);
        return;
      }
      this.selected = f[0];
    },
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal === oldVal) {
        return;
      }

      this.init(newVal);
    },
  },
  created() {
    if (this.value === '') {
      this.selected = this.options[0];
      return;
    }
    this.init(this.value);
  },
};
</script>

<style scoped lang="scss" type="text/scss">

  .access-policy-type-selector {
    ._button {
      display: flex;
      align-items: center;

      ._text {
        margin-right: .8rem;
      }

      ._icon-container {
        width: 1.1rem;

        ._icon {
          margin-top: .2rem;
          color: $font-color-tertiary;
        }
      }
    }
  }

  ._overlay {
    min-width: 24rem;
    padding: .4rem 0;

    ._item {
      ._left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
      }

      ._right {
        min-width: 1.2rem;
      }

      ._text {
        &.-danger {
          color: $error-color;

          &.-disabled {
            color: $error-color-disabled;
          }
        }

        &.-disabled {
          color: $font-color-disabled;
        }
      }

      ._description {
        font-size: $font-size-2;
        color: $font-color-secondary;

        &.-disabled {
          color: $font-color-secondary-disabled;
        }
      }
    }
  }
</style>
